<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import Input from '$comp/ui/input/input.svelte';
	import { Label } from '$comp/ui/label';
	import * as Accordion from '$lib/components/ui/accordion';
	import * as AlertDialog from '$lib/components/ui/alert-dialog/index';
	import { Button } from '$lib/components/ui/button';
	import { Checkbox } from '$lib/components/ui/checkbox/index.js';
	import { Textarea } from '$lib/components/ui/textarea/index.js';
	import Icon from '$shared/Icon.svelte';
  import { editingUser } from './stores';

	let showClose = false;
	let open = false;
	let formState = {
		id: '',
		company: '',
		firstName: '',
		lastName: '',
		email: '',
		expiresAt: '',
    roles: [],
		welcomeText: ''
	};
	export let showCompany: boolean = false;
	export let turnOffComments: boolean = false;
	export let status: 'form' | 'success' | 'fail' = 'form';

	const dispatch = createEventDispatcher();

  let userDetails = {
		company: '',
    email: '',
    full_name: '',
		expires_at: '',
		updated_at: '',
  };

  let errors:string[] = [];

	async function addEditManualUser(method: string) {
		try {
      const response = await fetch(`/users/${formState.id}`, {
        method: method,
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        body: JSON.stringify(formState),
      });

      if (!response.ok) {

        const errorMessage = await response.text();
              	console.log("Response not ok", errorMessage)
        status = 'fail';
        let parsedError = JSON.parse(errorMessage);
        errors = parsedError.errors;
        throw new Error(errorMessage);
      }

      const data = await response.json();
      console.log("Response data is", data);
			userDetails.company = data.company;
      userDetails.email = data.email;
      userDetails.full_name = data.full_name;
			userDetails.expires_at = data.expires_at;
			userDetails.updated_at = data.updated_at;
	    console.log("Success");
      dispatch('edit-user', { formState, userDetails });
      showClose = true;
	    status = 'success';
    } catch (error) {
      console.error(error);
    }
	}

	$: open, reset(true);

	function reset(clearForm?:boolean) {
		showClose = false;
		status = 'form';
    if (clearForm) {
      formState = {
        id: '',
				company: '',
        firstName: '',
        lastName: '',
        email: '',
        expiresAt: '',
        roles: [],
				welcomeText: ''
      };
    }
	}

  $: $editingUser, openFromRow();

  function openFromRow() {
    if ($editingUser != null) {
      formState = $editingUser;
      status = 'form';
      open = true;
    } else {
      open = false;
    }
  }
</script>

<AlertDialog.Root bind:open>
	<AlertDialog.Trigger asChild let:builder>
		<Button builders={[builder]} variant="default"><Icon icon="plus_plain" tiny svg_styling="mr-2" /> Invite User</Button>
	</AlertDialog.Trigger>
	<AlertDialog.Content>
		<AlertDialog.Header>
			<AlertDialog.Title>
				{#if $editingUser}
					Edit User
				{:else}
					Add New User
				{/if}
			</AlertDialog.Title>
			<AlertDialog.Description>
				{#if status == 'success' && $editingUser == null}
					<div class="flex flex-col gap-6 pt-6 pb-6">
						<div class="w-full text-center flex flex-col items-center">
							<p class="text-brand-blue-500 text-lg">Email invitation sent to </p>
              <div class="font-bold text-black">
                {formState.email}
              </div>
						</div>
					</div>
				{:else if status == 'success' && $editingUser != null}
					<div class="flex flex-col gap-6 pt-6 pb-6">
						<div class="w-full text-center flex flex-col items-center">
							<p class="text-brand-blue-500 text-lg">
								<span class="font-bold text-black">
									{userDetails.email}
								</span>
								successfully updated
							</p>
						</div>
					</div>
				{:else if status == 'fail'}
					<div class="flex flex-col gap-6 pt-6 pb-6">
						<div class="w-full text-center flex flex-col items-center">
							<p class="text-brand-orange text-lg">Unable to Add User</p>
              <div class="text-black">
                {userDetails.email}
              </div>
						</div>
						<div class="p-2 text-black">
              {#if errors && errors.length}
              {#each errors as error, i}
                <span>{i==0 ? '' : ', '}{error}</span>
              {/each}
              {/if}
            </div>
					</div>
				{:else}
					<div class="flex flex-col gap-6 pt-6">
						<div class="grid grid-cols-2 gap-2">
							<Label class="uppercase col-span-full text-xs">User Details</Label>
							<Input bind:value={formState.firstName} placeholder="First Name" />
							<Input bind:value={formState.lastName} placeholder="Last Name" />
							<Input
								bind:value={formState.email}
								placeholder="Email"
								type="email"
								class="col-span-2"
								name="email"
								required
							/>
						</div>

						<Accordion.Root>
							<Accordion.Item value="item-0">
								<Accordion.Trigger>Permissions</Accordion.Trigger>
								<Accordion.Content>
									<div class="col-span-full space-y-2">
										{#if !turnOffComments}
											<div class="flex items-center space-x-2">
												<Checkbox bind:bindGroup={formState.roles} value="manage_comments" id="manage_comments" />
												<Label
													for="manage_comments"
													class="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
												>
													Manage comments
												</Label>
											</div>
										{/if}
										<div class="flex items-center space-x-2">
											<Checkbox bind:bindGroup={formState.roles} value="manage_curations" id="manage_curations" />
											<Label
												for="manage_curations"
												class="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
											>
												Manage curations
											</Label>
										</div>
                    <div class="flex items-center space-x-2">
											<Checkbox bind:bindGroup={formState.roles} value="manage_users" id="manage_users" />
											<Label
												for="manage_users"
												class="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
											>
												Manage users
											</Label>
										</div>
										<div class="flex items-center space-x-2">
											<Checkbox bind:bindGroup={formState.roles} value="view_analytics" id="view_analytics" />
											<Label
												for="view_analytics"
												class="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
											>
												View analytics
											</Label>
										</div>
									</div>
								</Accordion.Content>
							</Accordion.Item>
							<Accordion.Item value="item-1">
								<Accordion.Trigger>Additional Settings</Accordion.Trigger>
								<Accordion.Content>
									<div class="w-full flex flex-col gap-4 px-2">
										<div class="col-span-full space-y-2">
											<Label class="uppercase col-span-full text-xs">Expires At</Label>
											<Input
												bind:value={formState.expiresAt}
												type="date"
												placeholder="Expires At"
											/>
										</div>
										<div class="col-span-full space-y-2">
											<Label class="uppercase col-span-full text-xs">Welcome Text</Label>
											<Textarea
												bind:value={formState.welcomeText}
												placeholder="Type your customized welcome email message here. (optional)"
											/>
										</div>
									</div>
								</Accordion.Content>
							</Accordion.Item>
							{#if showCompany}
								<Accordion.Item value="item-2">
									<Accordion.Trigger>Company</Accordion.Trigger>
									<Accordion.Content>
										<div class="col-span-full space-y-2 p-2">
											<Input bind:value={formState.company} placeholder="Acme LLC" />
										</div>
									</Accordion.Content>
								</Accordion.Item>
							{/if}
						</Accordion.Root>
					</div>
				{/if}
			</AlertDialog.Description>
		</AlertDialog.Header>
		<AlertDialog.Footer>
			{#if status == 'form'}
				<AlertDialog.Cancel on:click={()=>{
					$editingUser = null;
          reset(true);
				}}>Cancel</AlertDialog.Cancel>
			{/if}
			{#if status == 'fail' || ($editingUser && status == 'success')}
				<Button variant="outline" on:click={() => reset()}>Go Back</Button>
			{/if}
			{#if showClose}
				<AlertDialog.Action>Close</AlertDialog.Action>
			{:else if $editingUser}
				<Button on:click={() => addEditManualUser("PUT")}>Update User</Button>
			{:else}
				<Button on:click={() => addEditManualUser("POST")}>Invite User</Button>
			{/if}
		</AlertDialog.Footer>
	</AlertDialog.Content>
</AlertDialog.Root>
