<svelte:options customElement={{ tag: "bt-action-button", shadow: "none", }} />

<script lang="ts">
  export let buttonClasses = '';
  export let buttonStyles = '';
  export let buttonEl = null;
  export let design = '';
</script>

<button 
  type="button"
  class="action-button {buttonClasses} {design}"
  style={buttonStyles}
  on:click
  bind:this={buttonEl}
>
  <slot></slot>
</button>

<style>
  :global(.action-button svg) {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    margin-left: .5rem;
  }
  button {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: .5rem;
    padding: .5rem 1rem;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    font-family: "ibm-plex-sans";
    font-size: 16px;
    line-height: 1.5;
    border-width: 1px;
    border-radius: 1px;
  }
  button:has(:nth-child(2)) {
    justify-content: space-between;
  }
  button.secondary {
    background: theme("colors.white");
    color: theme("colors.neutral.900");
    &:hover {
      background: theme("colors.neutral.900");
      color: theme("colors.white");
      border-color: theme("colors.neutral.900");
    }
  }
  button.primary {
    background: theme("colors.neutral.900");
    color: theme("colors.white");
    border-color: theme("colors.neutral.900");
    &:hover {
      background: theme("colors.white");
      color: theme("colors.neutral.900");
    }
  }
  button.tertiary {
    background: transparent;
    color: theme("colors.neutral.500");
    border: none;
    &:hover {
      background: transparent;
      color: theme("colors.neutral.900");
    }
  }
</style>