<script lang="ts">
  // External imports
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';
  import { 
    createSvelteTable, getCoreRowModel, getSortedRowModel, getPaginationRowModel, renderComponent
  } from '@tanstack/svelte-table';
  import type { 
    ColumnDef, TableOptions, OnChangeFn, SortingState
  } from '@tanstack/svelte-table';
  // Shared utilities
  import { debounce } from '$shared/lib/debounce';
  // Table config and store
  import { dateStore } from '$comp/analytics/dateStore';
  import { analyticsTableFilterableColumns, analyticsTableColumnBanlist } from '$comp/analytics/analyticsTableConfig';
  // Shared table helpers
  import {
    loadTableDataAsJSON, loadTableDataAsCSV, createAndDownloadCSV
  } from '$shared/data-table/tableHelpers';
  // Other components
  import { Button } from '$lib/components/ui/button';
  import AnalyticsDataTableActions from './AnalyticsDataTableActions.svelte';
  // The actual table component
  import DataTable from '$lib/components/shared/data-table/DataTable.svelte';

  export let dataUrl: string = "";
  export let tableName: string = "";
  export let loading = false;
  
  // These hold the column configuration and table data respectively, once generated/fetched
  let cols: any[] = [];
  let data: any[] = [];
  // Sorting state and reactive config
  let sorting: any[] = [];
  $: sortKey  = {};
  // Fetched, read-only table column headings and tooltip data
  let columnHeaders: any[] = [];
  let tooltips: any = {};
  // Placeholder for the filter input
  let filterLabel: string = '';
  // What the user has actually input in the filter input
  let filterText: string = '';
  // User-input datetime range
  let startDate: string = '';
  let endDate: string = '';
  // Needed for initialization state of table being prettier
  const defaultColumns: ColumnDef[] = [];
  const defaultData = [{},{},{},{},{}];

  function createColumns() {
    for (const obj of columnHeaders) {
      for (const [key, value] of Object.entries(obj)) {
        if (analyticsTableColumnBanlist.includes(key)) continue;
        if (analyticsTableFilterableColumns.includes(key)) {
          cols.push({
            accessorKey: key,
            header: value
          })
        } else {
          cols.push({
            accessorKey: key,
            header: value,
            enableGlobalFilter: false
          });
        }
      }
    }

    if (showActions()) {
      cols.push({
        accessorKey: 'actions',
        header: '',
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (info) => renderComponent(AnalyticsDataTableActions, {row: info.row.original })
      });
    }
  }

  let csvIsProcessing = false;
  const handleExportCsv = () => {
    if (csvIsProcessing) {
      return;
    } else {
      csvIsProcessing = true;
    }

    loadTableDataAsCSV({
      dataUrl: dataUrl,
      filterText: filterText,
      sortKey: sortKey,
      startDate: startDate,
      endDate: endDate
    }).then(csvData => {
      createAndDownloadCSV({
        csvData: csvData,
        tableName: tableName,
        filterText: filterText,
        startDate: startDate,
        endDate: endDate
      })
    }).finally(() => {
      csvIsProcessing = false;
    })
  }

  function handleFilterSortData() {
    if (loading) {
      return;
    }

    loading = true;

    loadTableDataAsJSON({
      dataUrl: dataUrl,
      filterText: filterText,
      sortKey: Object.entries(sortKey).length > 0 ? sortKey : null,
      startDate: startDate,
      endDate: endDate
    }).then((tableData: any) => {
      parseRemoteData(tableData);
    }).finally(() => {
      loading = false;
    });
  }

  const filterTable = (e) => {
    filterText = e.detail;
    handleFilterSortData();
  }

  const sortTable = (e) => {
    let columnToSort = e.detail;
    if (columnToSort.getNextSortingOrder() === 'asc' || columnToSort.getNextSortingOrder() === 'desc') {
      sortKey = {
        id: columnToSort.id,
        order: columnToSort.getNextSortingOrder()
      }
    } else {
      sortKey = {}
    }
    columnToSort.toggleSorting();
    handleFilterSortData();
  }

  const setSorting: OnChangeFn<SortingState> = updater => {
    if (updater instanceof Function) {
      sorting = updater(sorting)
    } else {
      sorting = updater
    }
    options.update(old => ({
      ...old,
      state: {
        ...old.state,
        sorting,
      },
    }))
  }

  const options = writable<TableOptions>({
    data: defaultData,
    columns: defaultColumns,
    state: {
      columnVisibility: {
        'id': false,
      },
      sorting,
    },
    manualSorting: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  })

  function parseRemoteData(remote_data) {
    columnHeaders = remote_data.headers;
    data = remote_data.data;
    tooltips = remote_data.tooltips;

    if (columnHeaders.length > 0) {
      createColumns();
    }

    options.update(options => ({
      ...options,
      columns: cols,
      data: data
    }));
  }

  const table = createSvelteTable(options)

  function showActions() {
    return data.length > 0 && "url" in data[0];
  }

  onMount(async () => {
    dateStore.subscribe(() => {
      startDate = dateStore.getStart();
      endDate = dateStore.getEnd();

      if (startDate && endDate) {
        loading = true;
        loadTableDataAsJSON({
          dataUrl: dataUrl,
          filterText: filterText,
          sortKey: Object.entries(sortKey).length > 0 ? sortKey : null,
          startDate: startDate,
          endDate: endDate
        }).then((tableData: any) => {
          parseRemoteData(tableData);
        }).finally(() => {
          loading = false;
        });
      }
    });
  });


  // Determine what the filter label should be for this table based on whether
  // analyticsTableFilterableColumns contains the column ids in this table
  $: {
    let flattenedColumnHeaders = Object.assign({}, ...columnHeaders);

    let filterableColumns = Object.keys(flattenedColumnHeaders).filter(columnId => analyticsTableFilterableColumns.includes(columnId));

    if (filterableColumns.length > 0) {
      filterLabel = 'Filter by ';
      for (let i = 0; i < filterableColumns.length; i++) {
        if (i >= 1) { filterLabel += ' or ' }
        filterLabel += flattenedColumnHeaders[filterableColumns[i]]
      }
    } else {
      filterLabel = 'Filter...'
    }
  }
</script>

<DataTable
  table={table}
  tooltips={tooltips}
  canFilter
  filterLabel={filterLabel}
  on:filter-table={debounce(filterTable, 300)}
  on:sort-table={sortTable}
  sortServerside
  sortServersideSettings={sortKey}
>
  <svelte:fragment slot="buttons">
    <Button variant="outline" size="default" on:click={handleExportCsv}>Download</Button>
  </svelte:fragment>
</DataTable>